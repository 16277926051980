<template>
    <div class="row">
       <LeftSection :list_check_box="list_check_box" :links="data.data_links" />

        <!-- Right Section -->   
        <div class="col-12 col-lg-9 wrapper-box-fiche">                       
            <template v-for="(item, key) in list_check_box">      
                <div class="box-white-fiche" :key="key" v-if="show_block.includes(key) && item.enable" :id="key">
                    <!-- Section Title Start -->
                    <div class="box-icon-openbar">

                        <div class="box-click-open" @click="item.show = !item.show">                            
                            <h3 :class="item.orange_text ? 'text-orange' : ''" style="margin-bottom: 5px;">{{ $t(item.title) }}</h3>
                            <em :class="item.show ? 'fa fa-pull-right fa-angle-up arrow-up' : 'fa fa-pull-right fa-angle-down arrow-down'"></em>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <!-- Section Title End -->

                    <!-- Section Detail Start -->        

                    <!--information_general start-->
                    <InformationGeneral v-if="key == 'detail_information_general' && item.show" />
                    <!--information_general end-->

                    <Indication v-else-if="key == 'detail_indication' && item.show" />

                    <!-- detail_indication_under_consideration start-->
                    <div class="content-onglets"  v-else-if="key == 'detail_indication_under_consideration' && item.show">  
                        <TinyHTML :html="data.cdf.indication_under_consideration ? data.cdf.indication_under_consideration : '-'"/>                                               
                    </div>
                    <!-- detail_indication_under_consideration end-->
                  
                    <!-- treatment_line start -->
                    <TreatmentLine v-else-if="key == 'treatment_line' && item.show"/>
                    <!-- treatment_line end -->

                    <!-- conclusion cdf start -->
                    <div class="content-onglets" v-else-if="key == 'detail_cdf_conclusion' && item.show">  
                        <TinyHTML :html="data.cdf.conclusion"/>                             
                    </div>
                    <!-- conclusion cdf end -->


                    <!--  Conclusion and additional notes start-->                    
                    <Conclusion v-else-if="key == 'detail_Conclusion' && item.show" />                                         
                    <!--Conclusion additional notes end-->
                    
                    <!-- detail_formal_review_appeal start-->
                    <div class="content-onglets"  v-else-if="key == 'detail_formal_review_appeal' && item.show">  
                        <table class="comparateur2 border-top-gray">
                            <tbody>
                                <tr>
                                    <th colspan="2" width="50%">Formal review request date</th>
                                    <td colspan="2" width="50%">{{ DDMMMYY(data.cdf.formal_review_date)}}</td>
                                </tr>
                                <tr>
                                    <th colspan="2" width="50%" style="text-align: center;">Overall review result</th>
                                    <td colspan="2" width="50%">{{ check_empty(data.cdf.overall_review_result)}}</td>
                                </tr>
                                <tr>
                                    <th width="20%">Formal review request</th>
                                    <th width="20%">review decision record</th>
                                    <th width="20%">NCDF panel statement</th>
                                    <th width="20%">Grounds of appeal supplementary</th>
                                </tr>
                                <tr>
                                    <td> 
                                        <LinkUpload :href="'/uploads/'+$route.params.agency+'/'+data.drug_id+'/formal_review_request_pdf.pdf'" v-if="data.cdf.formal_review_request_pdf" target="_blank" rel="noopener">
                                            <div class="icon-fiche"></div>         
                                        </LinkUpload>
                                        <template v-else> - </template>                     
                                    </td>
                                    <td>
                                        <LinkUpload :href="'/uploads/'+$route.params.agency+'/'+data.drug_id+'/review_decision_record_pdf.pdf'" v-if="data.cdf.review_decision_record_pdf" target="_blank" rel="noopener">
                                            <div class="icon-fiche"></div>         
                                        </LinkUpload>
                                        <template v-else> - </template> 
                                    </td>
                                    <td> 
                                        <LinkUpload :href="'/uploads/'+$route.params.agency+'/'+data.drug_id+'/ncdf_panel_statement_pdf.pdf'" v-if="data.cdf.ncdf_panel_statement_pdf" target="_blank" rel="noopener">
                                            <div class="icon-fiche"></div>         
                                        </LinkUpload>
                                        <template v-else> - </template>
                                    
                                    </td>
                                    <td>
                                        <LinkUpload :href="'/uploads/'+$route.params.agency+'/'+data.drug_id+'/grounds_of_appeal_supplementary_pdf.pdf'" v-if="data.cdf.grounds_of_appeal_supplementary_pdf" target="_blank" rel="noopener">
                                            <div class="icon-fiche"></div>         
                                        </LinkUpload>
                                        <template v-else> - </template>
                                        
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- detail_formal_review_appeal end-->

                    <!-- key document for replace detail_formal_review_appeal -->
                    <KeyDocument v-else-if="key=='detail_key_documents' && item.show" :data="data.key_documents" :list_document_exist="data.list_document_exist"/>

                    <!-- Clinical evidence start -->
                    <div class="content-onglets" v-else-if="key == 'detail_clinical_evidence' && item.show">  
                        <TinyHTML :html="data.cdf.clinical_evidence"/>
                    </div>
                    <!-- Clinical evidence end -->

                    <!-- Clinical evidence scores start-->
                    <div class="content-onglets" v-else-if="key == 'detail_progression_free_survival_pfs' && item.show">
                        <table class="comparateur2 text-left" >  
                            <thead>
                                <tr>
                                    <th width="25%" ></th>
                                    <th width="75%" colspan="3" >Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td rowspan="2" style="vertical-align: middle"> <span v-if="data.cdf.progression_free_survival_pfs && data.cdf.progression_free_survival_pfs == 'Yes'">PFS</span>  </td>
                                    <td rowspan="2" class="text-center">  {{ check_empty(data.cdf.score)}}  </td>
                                    <td width="15%"  style=" vertical-align: middle">Absolute value</td>
                                    <td width="50%"><TinyHTML :html="data.cdf.absolute_values_for_benefit ? data.cdf.absolute_values_for_benefit : '-'"/></td>
                                </tr>
                                <tr>
                                    <td  style=" vertical-align: middle">Hazard ratio</td>
                                    <td><TinyHTML :html="data.cdf.hazard_ratio_pfs ? data.cdf.hazard_ratio_pfs : '-'"/></td>                        
                                </tr>
                                <tr>
                                    <td rowspan="2" style=" vertical-align: middle">OS</td>
                                    <td rowspan="2" class="text-center">{{ data.cdf.score_overall ?  get_value_cdf(data.cdf.score_overall) : '-'}}</td>
                                    <td style=" vertical-align: middle">Absolute value</td>
                                    <td><TinyHTML :html="data.cdf.absolute_values_for_benefit_overall ? data.cdf.absolute_values_for_benefit_overall : '-'"/> </td>                               
                                </tr>
                                <tr>
                                    <td style=" vertical-align: middle">Hazard ratio</td>                                
                                    <td><TinyHTML :html="data.cdf.hazard_ratio_os ? data.cdf.hazard_ratio_os : '-' "/></td> 
                                                        
                                </tr>
                                <tr>
                                    <td>Quality of life</td>
                                    <td colspan="3"> {{ data.cdf.score_quality_of_life ? $t('score_quality_of_life_'+data.cdf.score_quality_of_life) : '-'}}</td>
                                </tr>
                                <tr>
                                    <td>Toxicity</td>
                                    <td colspan="3"> {{ data.cdf.score_toxicity ? $t('score_toxicity_'+data.cdf.score_toxicity) : '-'}}</td>
                                </tr>
                                <tr>
                                    <td>Clinical unmet need</td>
                                    <td colspan="3">{{ data.cdf.score_degree ? $t('score_degree_'+data.cdf.score_degree) : '-'}} </td>
                                </tr>
                                <tr>
                                    <td>Strength of evidence</td>                                
                                    <td colspan="3">{{ data.cdf.score_strength ? $t('score_strength_'+data.cdf.score_strength) : '-'}} </td>                                    
                                </tr>
                                <tr>
                                    <td>Total score</td>                                
                                    <td colspan="3">{{ check_empty(data.cdf.total_clinical_score)}} </td>                                                                        
                                    
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!--Clinical evidence scores end-->

                                  
                    <!-- Economic evidence start-->
                    <div class="content-onglets" v-else-if="key == 'detail_decision_cost' && item.show"> 
                        <TinyHTML :html="data.cdf.decision_cost"/>
                        <table class="table text-left" >               
                            <table class="table text-left" >               
                        <table class="table text-left" >               
                            <table class="table text-left" >               
                        <table class="table text-left" >               
                            <tbody>
                                <tr>
                                    <td width="15%">Cost per QALY</td>
                                    <td>
                                        <div v-if="data.cdf.score_cost_per_qaly && data.cdf.score_cost_per_qaly != 'N/A'">{{$t('score_cost_per_qaly_'+data.cdf.score_cost_per_qaly)}}</div>
                                        <TinyHTML :html="data.cdf.text_cost_per_qaly ? data.cdf.text_cost_per_qaly : '-'" v-else />                                        
                                    </td>
                                </tr>
                                <tr>
                                    <td>Cost</td>
                                    <td>{{data.cdf.score_cost? $t('score_cost_'+data.cdf.score_cost) : '-'}}</td>
                                </tr>
                            </tbody>
                        </table>                        
                            </table>
                        </table>                        
                            </table>
                        </table>                        
                    </div>
                    <!-- Economic evidence end-->


                    <!--Treatment pathway start-->
                    <div class="content-onglets" v-else-if="key == 'detail_treatment_pathway_and_other_key_clinical_issues' && item.show">
                        <TinyHTML :html="data.cdf.treatment_pathway_and_other_key_clinical_issues"/>
                    </div>
                    <!--Treatment pathway end-->

                    <!-- detail_additional_information -->
                    <div class="content-onglets" v-else-if="key == 'detail_additional_information' && item.show">
                        <TinyHTML :html="data.cdf.additional_information"/>
                    </div>

                   
                    <!-- 'sub_detail/EconomicEvaluation.ctp' -->
                    <EconomicEvaluation v-else-if="key == 'detail_EconomicEvaluation' && item.show" :data="data.data_links['EconomicEvaluation']" />
                  
                    <EssaisClinique v-else-if="key == 'detail_clinical_trials' && item.show" :datas="data.essais_clinique"/>
                </div>
            </template>
            
            <!-- Link Section Start-->       
            <LinkAgency v-for="(data_agency,Agency) in data.data_links" :data="data_agency" :link_agency="Agency" :key="Agency" />
            <!-- Link Section End -->

        </div>
        <!-- Right Section End -->
    </div>
</template>

<script>
import LinkUpload from '../LinkUpload.vue'
import LeftSection from '../LeftSection.vue'
import Conclusion from '../conclusion.vue'
import TreatmentLine from '../treatment_line.vue' 
import EconomicEvaluation from '../economic_evaluation.vue'
import LinkAgency from '../LinkAgency.vue'
import EssaisClinique from '../../elements/essais_clinique_detail.vue'
import KeyDocument from '../key_document.vue'
import InformationGeneral from '../InformationGeneral.vue'
import Indication from '../Indication.vue'
import { DDMMMYY, get_value_cdf, check_empty, check_detail_conclusion, key_documents  } from '../../../utils'
import TinyHTML from '../tinymce_html.vue'
export default {
    name: 'cdf',
    components : {
        LinkUpload,
        LeftSection, 
        Conclusion,
        TreatmentLine,
        EconomicEvaluation,
        LinkAgency,
        EssaisClinique,
        KeyDocument,
        InformationGeneral,
        Indication,
        TinyHTML
    },
    data(){
        return {
            list_check_box : {
                // show -> show detail, 
                // enable -> show [check box] on left box, 
                // orange_text -> if true: color of title text is orange
                detail_information_general : {'title' : 'information generale', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_indication : {'title' : 'Indication', 'show' : true, 'enable' : false, 'orange_text':false} ,
                detail_indication_under_consideration : {'title' : 'Indication under consideration', 'show' : true, 'enable' : false, 'orange_text':false} ,
                treatment_line : {'title' : 'population', 'show' : true, 'enable' : false, 'orange_text':false} ,
                detail_cdf_conclusion : {'title' : 'Decision', 'show' : true, 'enable' : false, 'orange_text':false} ,
                detail_Conclusion : {'title' : 'Rationale and Commentary', 'show' : true, 'enable' : false, 'orange_text':true} ,
                detail_formal_review_appeal : {'title' : 'Formal review/Appeal', 'show' : true, 'enable' : false, 'orange_text':false} ,
                detail_clinical_evidence : {'title' : 'Clinical evidence', 'show' : true, 'enable' : false, 'orange_text':false} ,
                detail_progression_free_survival_pfs : {'title' : 'Clinical evidence scores', 'show' : true, 'enable' : false, 'orange_text':false} ,
                detail_decision_cost : {'title' : 'Economic evidence', 'show' : true, 'enable' : false, 'orange_text':false} ,
                detail_treatment_pathway_and_other_key_clinical_issues : {'title' : 'Treatment pathway', 'show' : true, 'enable' : false, 'orange_text':false} ,
                detail_additional_information : {'title' : 'Additional information', 'show' : true, 'enable' : false, 'orange_text':false} ,
                detail_EconomicEvaluation : {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true },
                detail_clinical_trials : {'title' : 'Clinical trials', 'show' : true, 'enable' : false, 'orange_text':true },
                detail_key_documents : {'title' : 'Key documents', 'show' : true, 'enable' : false, 'orange_text':false},//for replace detail_formal_review_appeal

            },
        }
    },
    created(){
        // condition to show check box in left section [enable]

        if(this.data && this.data.cdf){
            
            if(this.data.ma_date){ 
                this.list_check_box['detail_information_general'].enable = true
            }     
            if(this.data['indication_en']){
                this.list_check_box['detail_indication'].enable = true
            }
            if (this.data.cdf.indication_under_consideration){
                this.list_check_box['detail_indication_under_consideration'].enable = true
            }
            if(this.data.treatment_line || this.data.specificity || this.data.administration)  { 
                this.list_check_box['treatment_line'].enable = true
            }
            if (this.data.cdf.conclusion){ 
                this.list_check_box['detail_cdf_conclusion'].enable = true
            }
            if ( (this.data.cdf.formal_review_date && this.data.cdf.formal_review_date != '000-00-00' )
            || this.data.cdf.formal_review_request_pdf
            || this.data.cdf.review_decision_record_pdf
            || this.data.cdf.ncdf_panel_statement_pdf
            || this.data.cdf.grounds_of_appeal_supplementary_pdf
            ){
                this.list_check_box['detail_formal_review_appeal'].enable = true

            }
            //for replace detail_formal_review_appeal 
            if(key_documents(this.data.key_documents) && key_documents(this.data.key_documents).length > 0){ 
                this.list_check_box['detail_key_documents'].enable = true
            }
            if (this.data.cdf.clinical_evidence){ 
                this.list_check_box['detail_clinical_evidence'].enable = true
            }
            if ( this.data.cdf.progression_free_survival_pfs 
            || this.data.cdf.score
            || this.data.cdf.absolute_values_for_benefit
            || this.data.cdf.hazard_ratio_pfs
            || this.data.cdf.score_overall
            || this.data.cdf.hazard_ratio_os
            || this.data.cdf.score_quality_of_life
            || this.data.cdf.ncdf_total_score
            || this.data.cdf.score_strength
            || this.data.cdf.score_toxicity
            ){
                this.list_check_box['detail_progression_free_survival_pfs'].enable = true

            }
            if ( this.data.cdf.decision_cost
            || this.data.cdf.score_cost_per_qaly
            || this.data.cdf.text_cost_per_qaly
            || this.data.cdf.score_cost
            ){
                this.list_check_box['detail_decision_cost'].enable = true
            }                
            if(this.data.cdf.treatment_pathway_and_other_key_clinical_issues) {
                this.list_check_box['detail_treatment_pathway_and_other_key_clinical_issues'].enable = true                    
            }
            if (this.data.cdf.additional_information) {
                this.list_check_box['detail_additional_information'].enable = true  
            }
            
            if(this.data.data_links && this.data.data_links['EconomicEvaluation'] && this.data.data_links['EconomicEvaluation'].length > 0) { 
                
                this.list_check_box['detail_EconomicEvaluation'].enable = true                  
            }
            if (this.data.cdf.additional_notes || 
                this.data.cdf.score_overall || 
                this.data.cdf.score_quality_of_life || 
                this.data.cdf.score_toxicity || 
                this.data.cdf.score_degree || 
                this.data.cdf.score_cost || 
                this.data.cdf.strength_of_evidence || 
                this.data.cdf.total_clinical_score || 
                this.check_detail_conclusion(this.data, this.$i18n.locale)) { 

                this.list_check_box['detail_Conclusion'].enable = true                        
            }
            if(this.data.essais_clinique && this.data.essais_clinique.length > 0) { 
                this.list_check_box['detail_clinical_trials'].enable = true
            }
            
        }
    },
    methods : {      
       DDMMMYY,
       get_value_cdf,
       check_empty,
       check_detail_conclusion,
       key_documents
    },
    computed : {
        data(){
            return this.$store.getters['detail/data']
        },
        show_block : {
            set(val) { this.$store.dispatch('detail/set_show_block', val)},
            get() {return this.$store.getters['detail/show_block']}
        },
        view_enabled() {  
            return this.$store.getters['detail/view_enabled']        
        }
    },
}

</script>

<style scoped>
a{
    color: #000;
}
.table td, .table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}
</style>